const contactData = {
  email: {
    label: 'Email',
    value: 'kendall@kendallwhitman.photography',
  },
  phone: {
    label: 'Call or Text',
    value: '8175839953',
    valueFormatted: '817-583-9953'
  },
}

export default contactData
